@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.select-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  flex-direction: column;

  label {
    margin-bottom: 4px;
    color: $ui-text-dark;
  }

  .select {
    position: relative;
    width: 100%;
    height: 45px;
    padding: 0 36px 0 16px;
    border: 1px solid $ui-line-gray;
    border-radius: 3px;
    color: $ui-text-dark;
    font: {
      family: "Montserrat", sans-serif;
      weight: 400;
    }
    @include font-size($ui-font-14);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    option {
      color: $ui-text-dark;
      font: {
        family: "Montserrat", sans-serif;
        weight: 400;
      }
      @include font-size($ui-font-14);
    }

    &-icon {
      position: absolute;
      top: calc(50% + 3px);
      right: 24px;
      width: 16px;
      height: 10px;
    }
  }
}

@media all and (max-width: $ipad) {
  .select-wrap {
    margin-bottom: 20px;
  }
}
