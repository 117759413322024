@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.card-avatar {
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;

  &.orange {
    background: #ffa231;
    background: -moz-linear-gradient(-45deg, #ffa231 0%, #ff6d00 100%);
    background: -webkit-linear-gradient(-45deg, #ffa231 0%, #ff6d00 100%);
    background: linear-gradient(135deg, #ffa231 0%, #ff6d00 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa231', endColorstr='#ff6d00',GradientType=1 );
  }

  span {
    color: $ui-text-white;
    font-size: 16px;
    font-weight: 600;
  }
}
