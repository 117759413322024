@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.card-add-photo-wrap {
  position: relative;
  width: 100%;
}

.card-add-photo-image-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: $ui-bg-light;

  img {
    width: 46px;
    height: 46px;
  }
}

.card-add-photo-image {
  position: relative;
  padding-top: 80%;
  border-radius: 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  button {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $red;
    color: $white;
    font-size: 10px;
    font-weight: 600;
  }
}

.card-add-photo {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 80%;
  border-radius: 6px;
  background-color: $lightGray;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.35s ease-out 0s;

  .card-add-photo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 1;

    &.editable {
      opacity: 0;
      transition: all 0.35s ease-out 0s;
    }

    & > p {
      width: 100%;
      color: $red !important;
      font-weight: 600 !important;
      text-align: center;
    }
  }

  .card-add-photo-icon {
    width: 42px;
    height: 42px;
    fill: $red;
  }
}

/* mouse device */
@media (pointer: fine) {
  .card-add-photo {
    &:hover {
      opacity: 0.65;

      .card-add-photo-overlay {
        &.editable {
          opacity: 1;
          transition: all 0.35s ease-out 0s;
        }
      }
    }
  }
}
