@import "../../styles/colors.scss";

.modal-image-crop {
  width: 100%;
  height: 100%;

  .inter-image-crop-overlay {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .inter-image-crop-wrap {
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 460px;
      height: 520px;
      padding-bottom: 20px;
      border-radius: 4px;
      background-color: $ui-bg-white;
      box-shadow: 0 0 4px rgba($violet, 0.45);

      .image-crop {
        width: 460px;
        height: 460px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 80px;
      }
    }
  }
}
