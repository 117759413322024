@import "../../../styles/colors.scss";
@import "../../../styles/media.scss";

.board-button {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
  margin: 16px 0;
  padding: 0 16px;
  border-radius: 4px;

  .board-button-icon {
    width: 10px;
    height: 10px;
    margin-right: 16px;
    fill: $ui-icon-gray;
  }

  p {
    color: $ui-text-gray;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}

/* desktop */
@media (pointer: fine) {
  .board-button {
    &:hover {
      cursor: pointer;
      background-color: $ui-bg-gray;
      transition: all 0.3s ease-out 0s;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
