@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.modal-notification {
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;

  .modal-container {
    position: relative;
    width: 100%;
    max-width: 1024px;
    padding: 20px;
    border-radius: 8px;
    background-color: $ui-bg-white;
    box-shadow: 0 0 19px $ui-shadow-table;

    &.table {
      padding: 0;
      box-shadow: none;
      background-color: transparent;
    }

    & > h3 {
      margin-bottom: 12px;
    }

    .modal-close {
      position: absolute;
      top: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 9px;
      font-size: 20px;
      font-weight: 800;
      background-color: $ui-bg-dark;
      color: $ui-text-light;
    }

    .modal-add-form {
      grid-column-gap: 20px;
      grid-row-gap: 12px;
      grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    }
  }
}
