@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.card-user {
  width: 100%;
  margin: 12px 0 0 0;
  padding: 8px;
  border: 1px solid $ui-line-gray;
  border-radius: 8px;

  .card-user-wrap {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
  }

  .card-user-control {
    flex-direction: row;
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid $ui-line-gray;

    .card-user-control-button {
      margin-right: 8px;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: $ui-btn-primary;
      color: $ui-text-white;
      font-size: 12px;
    }
  }
}
