@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.select-dates-wrap {
  position: relative;
  flex-direction: column;
  display: block;
  width: 200px;
  margin: 12px;
  padding: 8px 20px;
  border: 1px solid $ui-line-gray;

  & > p {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .input-warn-text {
    position: absolute;
    bottom: -16px;
    left: 0;
    color: $ui-text-red;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: block;
  }

  .react-datepicker-wrapper {
    display: inline-block;
  }

  .react-datepicker__input-container {
    display: inline-block;
  }

  .react-datepicker {
    display: inline-block;
  }
  
  .react-datepicker__day-name {
    display: inline-block;
  }

  .react-datepicker__day {
    display: inline-block;
  }
}

 