@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.table-adaptive {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.table {
  width: 100%;
  margin: 16px 0;
  border-radius: 8px;
  background-color: $ui-bg-white;
  box-shadow: 0 0 19px $ui-shadow-table;
  border-collapse: collapse;
  border-spacing: 0;
}

@media all and (max-width: $ipad) {

}
