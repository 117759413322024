@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.statistic-page {
  flex: 1 1;
  padding: 20px 0;

  .statistic-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }

  .statistic-wrap {
    width: 100%;
    margin-top: 20px;
    border-radius: 4px;
    background-color: $ui-bg-white;

    .statistic-orders {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px;

      .statistic-order-graph {
        position: relative;

        &.center {
          align-items: center;
        }

        .rv-xy-plot__series--bar {
          rect {
            opacity: 0.88 !important;

            &:nth-child(1) {
              fill: #ffa000 !important;
              stroke: #ffa000 !important;
            }

            &:nth-child(2) {
              fill: #388e3c !important;
              stroke: #388e3c !important;
            }

            &:nth-child(3) {
              fill: #1976d2 !important;
              stroke: #1976d2 !important;
            }

            &:nth-child(4) {
              fill: #e64a19 !important;
              stroke: #e64a19 !important;
            }

            &:nth-child(5) {
              fill: #616161 !important;
              stroke: #616161 !important;
            }
          }
        }
      }
    }

    .graph-legend {
      position: relative;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: -8px;

      .graph-legend-item {
        position: relative;
        flex-direction: row;
        align-items: center;
        padding-right: 16px;

        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          margin-bottom: 4px;
          margin-right: 8px;
          border-radius: 50%;
          background-color: $ui-icon-menu;
        }

        &.yellow {
          &::before {
            background-color: #ffcd56;
          }
        }
        &.violet {
          &::before {
            background-color: #9966ff;
          }
        }
        &.blue {
          &::before {
            background-color: #059bff;
          }
        }
        &.pink {
          &::before {
            background-color: #ff6384;
          }
        }
        &.green {
          &::before {
            background-color: #4bc0c0;
          }
        }
      }
    }

    &.statistic-dates-select {
      flex-direction: row;
      align-items: center;
    }
  }
}

@media all and (max-width: $ipad) {
  .statistic-page {
    padding: 20px 0;

    .statistic-info {
      grid-template-columns: 1fr;
    }

    .statistic-wrap {
      .statistic-orders {
        grid-template-columns: 1fr;
        padding: 0;
      }
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
