.check {
    width: 100%;
    border: 2pt solid #ededed;
}

.check a {
    color: #ff7890;
    text-decoration: underline;
    cursor: pointer;
    font-family: arial, sans-serif;
}

.check a:hover {
    text-decoration: none;
}

.check__id {
    text-align: center;
    height: auto;
    /* line-height: 114pt; */
    border-bottom: 1pt solid #f1f1f1;
    padding: 4pt 0;
}

.check__id p {
    font-family: arial, sans-serif;
    font-weight: bold;
    font-size: 10pt;
    color: #000;
}

.check__id p span {
    font-size: 10pt;
    color: #000;
    font-family: arial, sans-serif;
}

.check__left,
.check__right {
    width: 100%;
    padding: 16pt;
}

.check__left tr {
    vertical-align: top;
}

.check__left tr:nth-child(2) {
    border-bottom: 1pt solid #f1f1f1;
}

.check__left tr:nth-child(2) td {
    padding-bottom: 20pt;
}

.check__left tr:nth-child(3) {
}

.check__left tr:nth-child(3) td {
    padding-top: 20pt;
}

.check__left tr td:first-child {
    width: 200pt;
    font-size: 10pt;
    color: #000;
    font-family: arial, sans-serif;
    font-weight: bold;
}

.check__left tr td:last-child {
    color: #000;
    font-size: 10pt;
    padding-left: 20pt;
    padding-bottom: 10pt;
}

.check__right tr {
    vertical-align: middle;
}

.check__right tr:last-child td {
    padding-top: 29pt;
}
/* .check__right tr:last-child td:first-child {
  font-family: arial, sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 24pt;
} */
.check__right tr:last-child td:last-child {
    font-size: 10pt;
    color: #000;
    text-align: right;
    white-space: nowrap;
}

.check__right tr td:first-child {
    border-left: solid thin;
    border-width: 1pt;
    border-color: #ededed;
    padding-left: 5pt;
}

.empty_row {
    height: 10pt !important;
}

.check__right tr:nth-child(2) {
    border-bottom: 1pt solid #f1f1f1;
}

.check__right tr:nth-child(2) td {
    padding-bottom: 20pt;
}

.check__right tr td:first-child {
    font-size: 10pt;
    //color: #000;
    font-family: arial, sans-serif;
    font-weight: 600;
}

.check__right tr td:last-child {
    color: #000;
    font-size: 10pt;
}

.check__item td {
    padding-top: 20pt;
}

.check__item td:last-child {
    text-align: right;
}

.check__item td:last-child p {
    font-size: 10pt;
    color: #000;
    font-family: arial, sans-serif;
    font-weight: bold;
}

.check__item td:last-child p span {
    font-size: 10pt;
    color: #000;
}

.check__count {
    border-bottom: 1pt solid #f1f1f1;
}

.check__count p {
    font-size: 10pt;
    color: #000;
}

.check__count p span {
    font-family: arial, sans-serif;
    font-weight: bold;
}
