@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.page-text {
  width: 100%;

  h3 {
    margin-bottom: 12px;
    color: $ui-text-dark;
    font-size: 22px;
    font-weight: 700;
  }

  .page-text-wrap {
    width: 100%;

    p {
      margin-top: 30px;
      margin-bottom: 20px;
      color: $ui-text-dark;
      font-size: 16px;

      strong {
        font-weight: 800;
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .page-text {
    h3 {
      margin-bottom: 25px;
    }
  }
}
