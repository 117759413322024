@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.send-msg {
  flex-direction: row;
  width: 100%;

  .send-msg-add-image {
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 48px;
    height: 40px;
    margin-right: 4px;
    border-radius: 8px;
    background-color: $ui-bg-white;
    transition: all 0.35s ease-out 0s;

    .send-msg-add-image-icon {
      width: 18px;
      height: 18px;
      fill: $ui-icon-gray;
    }

    &:hover {
      background-color: $ui-bg-gray;
      transition: all 0.35s ease-out 0s;
    }
  }

  .send-msg-input {
    flex-grow: 1;
    flex-shrink: 1;
    height: 40px;
    padding: 0 12px;
    border-radius: 8px;
    background-color: $ui-bg-gray;
  }

  .send-msg-button {
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 48px;
    height: 40px;
    margin-left: 4px;
    border-radius: 8px;
    background-color: $ui-bg-white;
    transition: all 0.35s ease-out 0s;

    .send-msg-button-icon {
      width: 20px;
      height: 20px;
      fill: $ui-icon-gray;
    }

    &:hover {
      background-color: $ui-bg-gray;
      transition: all 0.35s ease-out 0s;
    }
  }
}